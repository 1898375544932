import Home from "./views/Home.js";
// import Manual from "./components/Manual";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {

  
  

  return (
    <div className="App">

      <Home/>

    </div>
  );
}

export default App;
