import { useEffect, useState } from "react"
import Navbar from "../components/Navbar/Navbar"
import Footer from "../components/Footer/Footer"
import ObjectButton from "../components/ObjectButton/ObjectButton"
import Loader from "../components/Loader/Loader"
import ManualContainer from "../components/ManualContainer/ManualContainer"

export default function Home() {

    // //STATES
    const [ selectedModel, setSelectedModel ] = useState(null)
    const [ isLoading, setIsLoading ] = useState(false)

    //VARIABLES
    const allObjects = {
        puno : {
            name: 'puno',
            category: 'lámpara',
            height: '224.',
            width: '150.',
            steps: [
                {
                    text:'Colocá una lámpara con rosca E27 (en el pie de tu lámpara Puno.',
                    alert: null,
                },
                {
                    text:'Encastrá la pantalla cerámica en el pie.',
                    alert: 'Ojo! Tiene una posición única.',
                },
                {
                    text:'Enchufá tu lámpara, encendela y disfrutala.',
                    alert: null,
                }
            ],
            materials: 'Cerámica.',
            zocalo: 'E27.',
            lamp: 'Tipo gota, 7W.',
            cleaning:'Recomendamos limpiar el exterior de nuestras lámparas con paño apenas húmedo.'
        },
        ulam: {
            name: 'ulam',
            category: 'lámpara',
            height: '155.',
            width: '235.',
            steps: [
                {
                    text:'Colocá una lámpara con rosca e27 en la base de tu lámpara Ulam.',
                    alert: null,
                },
                {
                    text:'Sostené con una mano ambos brazos del resorte y colocá el globo de vidrio. Acomodalo con cuidado en su base.',
                    alert: 'Ojo! Soltá lentamente el resorte hasta que ambos brazos apoyen en la boca del globo para evitar golpearlo.',
                },
                {
                    text:'Enchufá tu lámpara, encendela y disfrutala.',
                    alert: null,
                }
            ],
            materials: 'Cerámica y Vidrio Reciclado.',
            zocalo: 'E27.',
            lamp: 'Tipo gota, 7W.',
            cleaning:'Recomendamos limpiar el exterior de nuestras lámparas con paño apenas húmedo.'
    
        },
        kurba: {
            name: 'kurba',
            category: 'lámpara',
            height: '155.',
            width: '235.',
            steps: [
                {
                    text:'Colocá una lámpara con rosca e27 en la base de tu lámpara Kurba.',
                    alert: null,
                },
                {
                    text:'Sostené con una mano ambos brazos del resorte y colocá el globo de vidrio. Acomodalo con cuidado en su base.',
                    alert: 'Ojo! Soltá lentamente el resorte hasta que ambos brazos apoyen en la boca del globo para evitar golpearlo.',
                },
                {
                    text:'Enchufá tu lámpara, encendela y disfrutala.',
                    alert: null,
                }
            ],
            materials: 'Cerámica y Vidrio Reciclado.',
            zocalo: 'E27.',
            lamp: 'Tipo gota, 7W.',
            cleaning:'Recomendamos limpiar el exterior de nuestras lámparas con paño apenas húmedo.'
            
        }
    }

    //FUNCTIONS
    const handleSelected = (model) => {
        setIsLoading(true)
        if(model){
            setSelectedModel(model)
        }
    }

    const deleteSelection = () => {
        setIsLoading(true)
        setSelectedModel(null)
    }

    const showLoader = () => {

        // setIsLoading(true)
        setTimeout(()=>{
            setIsLoading(false)
        }, 300)

    }

    useEffect(() => {
        showLoader()
    },[selectedModel])

    return(
        <div className="home view">
            <Navbar deleteSelection={deleteSelection} selectedModel={selectedModel}/>
            {/* <Loader/> */}
            {
                isLoading ?
                <Loader/>
                :
                <>
                    {selectedModel ?
                        <ManualContainer model={selectedModel}/>
                    :
                    
                        <div className="main-object-button-container">
                            <h2>Elegí tu manual:</h2>
                            <ObjectButton model={allObjects.puno} handleSelected={handleSelected} />
                            <ObjectButton model={allObjects.ulam} handleSelected={handleSelected} />
                            <ObjectButton model={allObjects.kurba} handleSelected={handleSelected} />
                        </div> 
                    }
                </>
            }
            <Footer/>
        </div>
    )
}