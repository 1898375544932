export default function ObjectButton({model, handleSelected}) {

    //FUNCTIONS
    const capitalizeWords = (string) =>{
        return(
            string
                .toLowerCase()
                .split(' ')
                .map((word)=>word.charAt(0).toUpperCase()+word.slice(1))
                .join(' ')
        )
    }

    return(
        <div className="main-object-button" onClick={()=>{handleSelected(model)}}>
            <div className="text-container">
                <p>{capitalizeWords(model.name)}</p>
                <p>{capitalizeWords(model.category)}</p>
            </div>
            <img src={`images/${model.name}_full.svg`} alt={`${model.name} lamp`}/>
        </div>
    )
}