export default function Footer() {

    //FUNCTIONS
    const getYear = () => {
        return new Date().getFullYear()
    }

    return(
        <footer className="footer-main-container">
            <p>{`® Momo ${getYear()}`}</p>
            <div className='icon-container'>
                <a 
                    target='_blank'
                    rel='noopener noreferrer' 
                    href={'https://holamomo.ar'}
                    >
                    <img src='/images/shopIcon.svg' alt='tienda store'/>
                        
                </a>
                <a 
                    target='_blank'
                    rel='noopener noreferrer' 
                    href={'https://instagram.com/holamomo.ar'}
                    >
                    <img src='/images/igIcon.svg' alt='instagram'/>
                </a>
                
            </div>
        </footer>
    )
}