export default function Loader () {
    return(
        <div className="loader-main-container">
            <div className="lds-circle">
                <div>
                    <img src="images/isomomo.svg" alt="logo momo"/>
                </div>
            </div>
        </div>
    )
}