export default function SpecsContainer({model}){
    return(
        <div className="specs-container">
            <h2>Sobre el producto:</h2>
            <p><span>Materiales:</span>{model.materials}</p>
            <p><span>{"Zócalo"}:</span>{model.zocalo}</p>
            <p><span>{"Lámpara recomendada"}:</span>{model.lamp}</p>
            <p><span>{"Alto (mm)"}:</span>{model.height}</p>
            <p><span>{"Diámetro (mm)"}:</span>{model.width}</p>
            <p><span>{"Limpieza"}:</span>{model.cleaning}</p>
        </div>
    )
}