export default function Navbar({deleteSelection, selectedModel}) {
    return(
        <div className="navbar-main-container">
                <img alt="logo" src="images/logomomo.svg"/>
                {
                    selectedModel &&
                    <img alt="back arrow" src="images/back.svg" onClick={deleteSelection}/>
                }
        </div>
    )
}