// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'

import SpecsContainer from "../SpecsContainer/SpecsContainer"
import Step from "../Step/Step"

export default function ManualContainer({model}) {

    //FUNCTIONS
    const capitalizeWords = (string) =>{
        return(
            string
                .toLowerCase()
                .split(' ')
                .map((word)=>word.charAt(0).toUpperCase()+word.slice(1))
                .join(' ')
        )
    }

    return(
        <div className="manual-main-container">
            <h2>{capitalizeWords(model.name)}</h2>
            <div className="steps-container">

            {model.steps.map((step, idx)=>{
                return <Step step={step} idx={idx} model={model}/>                
            })

            }
            </div>
            <SpecsContainer model={model}/>
        </div>
    )
}