import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'

export default function Step({idx, model, step}) {
    return(
        <div className="step-container" key={idx}> 
            <div className="number-container">
                <p>{idx+1}</p>
            </div>
        
            <div className="image-container">
                <img src={`/images/steps/${model.name}${idx+1}.svg`} alt="step"/>
            </div>

            <div className="text-container">
                <p>{step.text}</p>
                {step.alert &&
                    <div className="tip-container">
                        <FontAwesomeIcon icon={faCircleExclamation}/>
                        <p>{step.alert}</p>
                    </div>
                }
            </div>
            {
                idx < model.steps.length - 1  &&

                <div className="step-division">
                    <span></span>
                </div>
            }

            
        </div>

    )
}